<template>
  <div>
    <!-- <div class="tmHeader__header_small">{{ header }}</div> -->
    <show-case :sku="NBmixCards" />
  </div>
</template>

<script>
import ShowCase from "../components/ShowCase.vue";

import NBMixNutsFruits50 from "../assets/NUTBERRY/NBmix/NB-nutsberry-50.jpg";
import NBMixCashewCrunberry100 from "../assets/NUTBERRY/NBmix/NB-cashwe-crunberry-100.jpg";
import NBMixNutsFruits100 from "../assets/NUTBERRY/NBmix/NB-nuts-fruits-100.jpg";
import NBMixNutsPineapple220 from "../assets/NUTBERRY/NBmix/NB-nuts-ananas-raisin-220.jpg";
import NBMixNutsBerry100 from "../assets/NUTBERRY/NBmix/NB-nuts-fruits-berry-100.jpg";
import NBMixAppleCinnamon from "../assets/NUTBERRY/NBmix/NB-applecinamon-220.jpg";
//мультикартинки
import NBMixNutsFruitsMulti from "../assets/NUTBERRY/NBmix/NB-nuts-fruits-multi.jpg";
import NBMixNutsFruitsBerryMulti from "../assets/NUTBERRY/NBmix/NB-nuts-fruits-berry-multi.jpg";

export default {
  name: "NutberryMix",
  components: {
    ShowCase,
  },
  data() {
    return {
      header: "Смеси орехов, сухофруктов и ягод",
      NBmixCards: [
        {
          picture: NBMixNutsFruits50,
          text: "NUTBERRY Смесь орехи и ягоды 50 г",
          id: 4,
          description:
            "Состав: изюм (виноград  сушеный  без косточек, подсолнечное масло, консервант Е220), жареные ядра арахиса и фундука, клюква сушеная (клюква, сахар, подсолнечное масло, регулятор кислотности - лимонная кислота) в изменяемых соотношениях.<br>Продукт может содержать следы кунжута, других орехов.<br>Пищевая ценность в 100 г: Белки 13,6 г, Жиры 26,3 г, Углеводы 40,5<br>Энергетическая ценность 472 Ккал.<br>Хранить при температуре от +5°С до +25°С и относительной влажности воздуха не более 75 %. Срок годности 12 месяцев.<br>ТУ 9164-002-60458573-15<br>Страна изготовитель: Россия",
        },
        {
          picture: NBMixCashewCrunberry100,
          text: "NUTBERRY Смесь кешью и клюква 100 г",
          id: 1,
          description:
            "Состав: ядра кешью жареные, клюква сушеная (клюква, сахар, подсолнечное масло, регулятор кислотности  лимонная кислота) в изменяемых соотношениях.<br>Продукт может содержать следы арахиса, кунжута, других орехов<br>Пищевая ценность в 100 г: Белки 22,0 г, Жиры 29,8 г, Углеводы 32,4<br>Энергетическая ценность 498,8 Ккал.<br>Условия хранения: при температуре от +5⁰С до +25°С и относительной влажности воздуха не более 75%. Срок годности 6 месяцев.<br>ТУ 9164-002-60458573-15<br>Страна изготовитель: Россия",
        },
        {
          picture: NBMixNutsFruits100,
          pictureMulti: NBMixNutsFruitsMulti,
          text: "NUTBERRY Смесь Орехи и фрукты 100, 220 г",
          id: 2,
          description:
            "Состав: ананас сушеный (ананас, сахар, регулятор кислотности лимонная кислота, консервант Е220, красители Е124, Е122, Е133, Е102, Е110),  изюм (виноград сушеный без косточек, масло подсолнечное, консервант Е220), сушеные ядра кешью, фундука, миндаля в изменяемых соотношениях.<br>Содержит красители, которые могут оказывать отрицательное влияние на активность и внимание детей.<br>Продукт может содержать фрагменты скорлупы, следы арахиса, кунжута, других орехов.<br>Пищевая ценность в 100 г: Белки 9,0 г, Жиры 26,5 г, Углеводы 45,9<br>Энергетическая ценность 459,2 Ккал.<br>Хранить при температуре от +5°С до +25°С и относительной влажности воздуха не более 75%. Срок годности 12 месяцев.<br>ТУ 9164-002-60458573-15<br>Страна изготовитель: Россия",
        },
        {
          picture: NBMixNutsPineapple220,
          text: "NUTBERRY Смесь жареных орехов с ананасом и изюмом 220 г",
          id: 3,
          description:
            "Состав: изюм (виноград сушеный без косточек, масло подсолнечное), ананас сушеный (ананас, сахар, регулятор кислотности лимонная кислота, консервант Е220, красители Е124, Е122, Е133, Е102, Е110), ядра миндаля жареные, ядра фундука жареные, ядра кешью жареные.<br>Содержит красители, которые могут оказывать отрицательное влияние на активность и внимание детей.<br>Продукт может содержать фрагменты скорлупы, следы арахиса, кунжута, других орехов.<br>Пищевая ценность в 100 г: Белки 10,0 г, Жиры 27,0 г, Углеводы 44,0<br>Энергетическая ценность 459 Ккал.<br>Хранить при температуре от +5°С до +25°С и относительной влажности воздуха не более 75%. Срок годности 12 месяцев.<br>ТУ 9164-002-60458573-15<br>Страна изготовитель: Россия",
        },
        {
          picture: NBMixNutsBerry100,
          pictureMulti: NBMixNutsFruitsBerryMulti,
          text: "NUTBERRY Смесь орехи фрукты ягоды 100, 220 г",
          id: 8,
          description:
            "Состав: ананас сушеный (ананас, сахар, регулятор кислотности лимонная кислота, консервант Е220, красители Е124, Е122, Е133, Е102, Е110),  изюм (виноград сушеный без косточек, подсолнечное масло, консервант Е 220), сушеные ядра миндаля и кешью, клюква сушеная (клюква, сахар, подсолнечное масло, регулятор кислотности  лимонная кислота) в изменяемых соотношениях.<br>Содержит красители, которые могут оказывать отрицательное влияние на активность и внимание детей.<br>Продукт может содержать фрагменты скорлупы, следы арахиса, кунжута, других орехов.<br>Пищевая ценность в 100 г: Белки 5,9 г, Жиры 13,1 г, Углеводы 58,5<br>Энергетическая ценность 380,8 Ккал.<br>Хранить при температуре от +5°С до +25°С и относительной влажности воздуха не более 75%. Срок годности 12 месяцев.<br>ТУ 9164-002-60458573-15<br>Страна изготовитель: Россия",
        },
        {
          picture: NBMixAppleCinnamon,
          text: 'NUTBERRY Смесь "яблоко с корицей" 220 г',
          id: 10,
          description:
            "Состав: яблоки сушеные (яблоки, регулятор кислотности  лимонная кислота, консервант  Е220), изюм (виноград сушеный без косточки, масло подсолнечное,  консервант Е220), ядра миндаля сушеные, вишня сушеная (вишня без косточки, сахар, регулятор кислотности лимонная кислота, консервант Е220, краситель  кармины), сахар, патока, корица молотая в изменяемых соотношениях.<br>Продукт может содержать косточки и их фрагменты, следы арахиса, кунжута, других орехов.<br>Пищевая ценность в 100 г: Белки 5,5 г, Жиры 13,1 г, Углеводы 55,6<br>Энергетическая ценность 369,7 Ккал.<br>Хранить при температуре от +5°С до +25°С и относительной влажности воздуха не более 75%. Срок годности 12 месяцев.<br>ТУ 9164-002-60458573-15<br>Страна изготовитель: Россия",
        },
      ],
    };
  },
};
</script>
